import { Injectable } from '@angular/core'
import { LocationModel } from '../models/Location.model'
import { SafetyMessageRideModel } from '../models/safety-message-ride.model'
import { MarkerType } from '../models/marker.type'

declare var naver: any

@Injectable()
export class MapService {
  private static toNaverCoord(location: LocationModel) {
    return new naver.maps.LatLng(location.lat, location.lng)
  }

  static createMap(domId: string) {
    return new naver.maps.Map(domId, {
      scrollWheel: true,
      zoom: 14,
      zoomControl: false,
      zoomControlOptions: {
        style: naver.maps.ZoomControlStyle.SMALL,
        position: naver.maps.Position.TOP_RIGHT,
      },
    })
  }

  static setTrafficLayer(map: any) {
    // 혼잡 교통정보 레이어(ctt) 추가
    // https://navermaps.github.io/maps.js.ncp/docs/tutorial-StyleMap-Custom-OverlayType.html
    // 시간이 지나도 업데이트 되지 않음에 유의하자.
    map.getOptions().mapTypes.set(
      naver.maps.MapTypeId.NORMAL,
      naver.maps.NaverStyleMapTypeOption.getNormalMap({
        overlayType: 'bg.ol.ts.ctt.lko',
      }),
    )
  }

  static fitbounds(map: any, ride: SafetyMessageRideModel) {
    var points = new Array<LocationModel>(
      ride.pickedUp,
      ride.destination,
      ride.vehicleLocation,
    )
    if (ride.waypoints != null) {
      if (ride.waypoints.length >= 1) {
        points.push(ride.waypoints[0])
      }
      if (ride.waypoints.length >= 2) {
        points.push(ride.waypoints[1])
      }
    }
    var topEnd = Math.max(...points.map((p) => p.lat))
    var bottomEnd = Math.min(...points.map((p) => p.lat))
    var leftEnd = Math.min(...points.map((p) => p.lng))
    var rightEnd = Math.max(...points.map((p) => p.lng))

    var bounds = new naver.maps.LatLngBounds(
      new naver.maps.LatLng(bottomEnd, leftEnd),
      new naver.maps.LatLng(topEnd, rightEnd),
    )
    if (window.screen.width >= 1200) {
      map.fitBounds(bounds, {
        top: 230,
        right: 0,
        bottom: 0,
        left: 0,
      })
    } else {
      map.fitBounds(bounds, {
        top: 15,
        right: 0,
        bottom: 0,
        left: 0,
      })
    }
  }

  static setRidePointMarkers(map: any, ride: SafetyMessageRideModel) {
    MapService.setMarker(map, ride.pickedUp, 'ORIGIN')
    MapService.setMarker(map, ride.destination, 'DESTINATION')
    if (ride.waypoints != null) {
      if (ride.waypoints.length >= 1) {
        MapService.setMarker(map, ride.waypoints[0], 'WAYPOINT1')
      }
      if (ride.waypoints.length >= 2) {
        MapService.setMarker(map, ride.waypoints[1], 'WAYPOINT2')
      }
    }
  }

  static setVehicleMarker(
    map: any,
    vehicleMarker: any,
    ride: SafetyMessageRideModel,
  ): any {
    if (vehicleMarker == null) {
      return MapService.setMarker(map, ride.vehicleLocation, 'VEHICLE')
    }
    vehicleMarker.setPosition(MapService.toNaverCoord(ride.vehicleLocation))
    return vehicleMarker
  }

  private static setMarker(
    map: any,
    location: LocationModel,
    markerType: MarkerType,
  ): any {
    return new naver.maps.Marker({
      icon: {
        content: MapService.getMarkerContent(markerType),
        // size: MapService.getMarkerSize(markerType),
        anchor: MapService.getMarkerAnchor(markerType),
      },
      position: MapService.toNaverCoord(location),
      map: map,
      zIndex: MapService.getMarkerZIndex(markerType),
    })
  }

  private static getMarkerContent(markerType: MarkerType): any {
    switch (markerType) {
      case 'ORIGIN': {
        return '<img src="/assets/images/startpoint@4x.png" class="startMarker">'
      }
      case 'DESTINATION': {
        return '<img src="/assets/images/destination@4x.png" class="destinationMarker">'
      }
      case 'WAYPOINT1': {
        return '<img src="/assets/images/stopover1@4x.png" class="stopover1Marker">'
      }
      case 'WAYPOINT2': {
        return '<img src="/assets/images/stopover2@4x.png" class="stopover2Marker">'
      }
      case 'VEHICLE': {
        return '<img src="/assets/images/ic_map_taxi@4x.png" class="vehicleMarker">'
      }
    }
  }

  // 2020.10.22 마커 사이즈는 이 함수를 통해서가 아니라 style.scss에서 결정한다.
  private static getMarkerSize(markerType: MarkerType): any {
    if (markerType === 'VEHICLE') {
      return new naver.maps.Size(32, 28)
    } else {
      return new naver.maps.Size(27, 46)
    }
  }

  // 2020.10.22 style.scss에 적힌 이미지 크기를 바탕으로 마커가 지칭하는 점과 실제 GPS 값이 같도록 한 값
  private static getMarkerAnchor(markerType: MarkerType): any {
    if (markerType === 'VEHICLE') {
      return new naver.maps.Point(16, 14)
    } else {
      return new naver.maps.Point(27, 46)
    }
  }

  private static getMarkerZIndex(markerType: MarkerType): any {
    switch (markerType) {
      case 'DESTINATION': {
        return 5
      }
      case 'WAYPOINT2': {
        return 4
      }
      case 'WAYPOINT1': {
        return 3
      }
      case 'ORIGIN': {
        return 2
      }
      case 'VEHICLE': {
        return 1
      }
    }
  }
}
