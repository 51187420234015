import { BrowserModule } from '@angular/platform-browser'
import { NgModule } from '@angular/core'

import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { RideService } from './services/ride.service'
import { MapService } from './services/map.service'
import { HttpClientModule } from '@angular/common/http'
import { RideComponent } from './pages/ride/ride.component'
import { ErrorComponent } from './pages/error/error.component'
import { DownloadComponent } from './download/download.component'
import { InfoComponent } from './pages/ride/info/info.component'

@NgModule({
  declarations: [
    AppComponent,
    RideComponent,
    ErrorComponent,
    DownloadComponent,
    InfoComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
  ],
  providers: [
    RideService,
    MapService,
  ],
  bootstrap: [
    AppComponent,
  ],
})
export class AppModule {
}
