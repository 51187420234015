import { Injectable } from '@angular/core'
import { environment } from '../../environments/environment'
import { HttpClient } from '@angular/common/http'
import { Router } from '@angular/router'
import { Observable, of } from 'rxjs'
import { SafetyMessageRideModel } from '../models/safety-message-ride.model'
import { catchError } from 'rxjs/operators'

@Injectable()
export class RideService {
  private apiServerUrl = environment.apiServerUrl

  constructor(
    private httpClient: HttpClient,
    private router: Router,
  ) {
  }

  getRide$(rideId: string): Observable<SafetyMessageRideModel | null> {
    return this.httpClient.get<SafetyMessageRideModel>(`${this.apiServerUrl}/rides/${rideId}`).pipe(
      catchError((e) => {
        if (e.status === 0) {
          return of(null)
        }
        this.router.navigateByUrl('/errors/' + btoa(encodeURIComponent(e.error.message)))
        return of(null)
      }),
    )
  }
}
