import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router'
import { Title } from '@angular/platform-browser'

@Component({
  selector: 'safety-message-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  isSent = false

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
  ) {
  }

  ngOnInit() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        (<any>window).gtag('config', 'UA-126022468-3', {
          'page_title': this.titleService.getTitle(),
          'page_path': event.urlAfterRedirects.split('?')[0],
          'custom_map': {
            'dimension1': 'channel',
            'dimension2': 'timestamp',
          },
        })
      }
      this.activatedRoute.queryParamMap.subscribe(queryParams => {
        let channel = queryParams.get('c')
        if (channel == null || this.isSent) {
          return
        }
        this.isSent = true;
        (<any>window).gtag('event', 'channel_dimension', { 'channel': channel })
        (<any>window).gtag('event', 'timestamp_dimension', { 'timestamp': (new Date()).getTime() })
      })
    })
  }
}
