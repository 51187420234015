import { Component } from '@angular/core'

@Component({
  selector: 'safety-message-download',
  templateUrl: './download.component.html',
  styleUrls: ['./download.component.scss'],
})
export class DownloadComponent {
  downloadUrl: string

  constructor() {
    if (this.isAndroid()) {
      this.downloadUrl = 'http://bit.ly/2C4ktP3'
    } else if (this.isIOS()) {
      this.downloadUrl = 'https://apple.co/2E7YXeS'
    } else {
      this.downloadUrl = 'https://tadatada.com'
    }
  }

  private isAndroid(): boolean {
    const isAndroid1 = /(android)/i.test(navigator.userAgent) && !window.MSStream
    const isAndroid2 = !!navigator.platform && /(iandroid)/i.test(navigator.platform)
    return isAndroid1 || isAndroid2
  }

  private isIOS(): boolean {
    const isIOS1 = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream
    const isIOS2 = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)
    return isIOS1 || isIOS2
  }
}
