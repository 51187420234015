import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'
import { ErrorComponent } from './pages/error/error.component'
import { RideComponent } from './pages/ride/ride.component'


const routes: Routes = [
  {
    path: '',
    component: ErrorComponent,
  },
  {
    path: 'errors/:message',
    component: ErrorComponent,
  },
  {
    path: 'rides/:rideId',
    component: RideComponent,
  },
  {
    path: '**',
    component: ErrorComponent,
  },
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
