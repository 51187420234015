<div class="rideInfo" *ngIf="ride">
  <div [ngSwitch]="ride?.status">
    <h1 *ngSwitchCase="'PICKED_UP'">
      {{ ride?.riderName }}님이 목적지로<br>
      이동 중입니다.
    </h1>
    <h1 *ngSwitchCase="'DROPPED_OFF'">
      {{ ride?.riderName }}님이 목적지에<br>
      도착하였습니다.
    </h1>
  </div>
  <hr>
  <table>
    <tr>
      <th>
        탑승 정보
      </th>
      <td *ngIf="!ride?.isDaeri">
        {{ ride?.vehicleModel }} {{ ride?.vehicleLicensePlate }}
      </td>
      <td *ngIf="ride?.isDaeri">
        타다 대리 탑승 ({{ ride?.vehicleLicensePlate }})
      </td>
    </tr>
    <ng-container *ngIf="isCharter">
      <tr *ngIf="isCharter">
        <th>
          탑승 시간
        </th>
        <td *ngIf="(ride?.pickedUpAt | date:'a') === 'AM'">
          오전 {{ ride?.pickedUpAt | date:'h시 mm분' }}
        </td>
        <td *ngIf="(ride?.pickedUpAt | date:'a') === 'PM'">
          오후 {{ ride?.pickedUpAt | date:'h시 mm분' }}
        </td>
      </tr>
      <tr>
        <th>
          대절 시간
        </th>
        <td>
          {{ charterDurationHours }}시간
        </td>
      </tr>
    </ng-container>
    <ng-container *ngIf="!isCharter">
    <tr>
      <th>
        출발 시간
      </th>
      <td *ngIf="(ride?.pickedUpAt | date:'a') === 'AM'">
        오전 {{ ride?.pickedUpAt | date:'h시 mm분' }}
      </td>
      <td *ngIf="(ride?.pickedUpAt | date:'a') === 'PM'">
        오후 {{ ride?.pickedUpAt | date:'h시 mm분' }}
      </td>
    </tr>
  </ng-container>
  </table>
</div>
