import { Component, Input } from '@angular/core'
import { SafetyMessageRideModel } from '../../../models/safety-message-ride.model'

@Component({
  selector: 'safety-message-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss'],
})
export class InfoComponent {
  @Input() ride?: SafetyMessageRideModel

  // 시간대절 판별 flag
  get isCharter() {
    return this.ride?.charterDurationInMinutes !== null
  }

  get charterDurationHours() {
    const charterDurationInMinutes = this.ride?.charterDurationInMinutes ?? 0
    return Math.floor(charterDurationInMinutes / 60)
  }
}
