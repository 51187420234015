import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { Title } from '@angular/platform-browser'

@Component({
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
})
export class ErrorComponent implements OnInit {
  message?: string

  constructor(
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
  ) {
  }

  ngOnInit() {
    this.activatedRoute.paramMap.subscribe(params => {
      let message = params.get('message')
      if (message == null) {
        this.message = '알 수 없는 에러입니다.'
      } else {
        this.message = decodeURIComponent(atob(message))
      }
      this.titleService.setTitle(this.message)
    })
  }
}
