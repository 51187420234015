import { Component, OnDestroy, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { MapService } from '../../services/map.service'
import { RideService } from '../../services/ride.service'
import { Title } from '@angular/platform-browser'
import { SafetyMessageRideModel } from '../../models/safety-message-ride.model'
import { environment } from '../../../environments/environment'

@Component({
  templateUrl: './ride.component.html',
  styleUrls: ['./ride.component.scss'],
})
export class RideComponent implements OnInit, OnDestroy {
  map: any = null
  vehicleMarker: any = null
  rideId?: string
  ride?: SafetyMessageRideModel
  lastUpdatedAt: number = 0

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private mapService: MapService,
    private rideService: RideService,
    private titleService: Title,
  ) {
  }

  ngOnInit() {
    this.activatedRoute.paramMap.subscribe(params => {
      let rideId = params.get('rideId')
      if (rideId == null) {
        this.router.navigateByUrl('/errors/' + btoa(encodeURIComponent('잘못된 접근입니다.')))
        return
      }
      this.rideId = rideId
      this.load(rideId)
    })
  }

  ngOnDestroy() {
    if (this.map != null) {
      this.map.destroy()
    }
  }

  private load(rideId: string | undefined) {
    if (rideId === undefined) {
      return
    }
    this.rideService.getRide$(rideId).subscribe(ride => {
      if (ride === null) {
        return
      }
      if (ride.status == 'CANCELED') {
        this.router.navigateByUrl('/errors/' + btoa(encodeURIComponent(`${ride.riderName}님의 탑승이 취소되었습니다.`)))
        return
      }
      this.ride = ride
      this.lastUpdatedAt = (new Date()).getTime()
      this.initialMap(ride)
      this.vehicleMarker = MapService.setVehicleMarker(this.map, this.vehicleMarker, ride)
      if (ride.status == 'PICKED_UP') {
        this.titleService.setTitle('목적지로 이동중입니다.')
        setTimeout(() => {
          this.load(rideId)
        }, environment.refreshIntervalMillis)
      }
      if (ride.status == 'DROPPED_OFF') {
        this.titleService.setTitle('목적지에 도착했습니다.')
      }
      setTimeout(() => {
        document.getElementById('refreshed')!.style.display = 'inline'
        document.getElementById('refreshing')!.style.display = 'none'
      }, 500)
    })
  }

  private initialMap(ride: SafetyMessageRideModel) {
    if (this.map != null) {
      return
    }
    this.map = MapService.createMap('rideMap')
    MapService.setRidePointMarkers(this.map, ride)
    MapService.fitbounds(this.map, ride)
    if (ride.status == 'PICKED_UP') {
      MapService.setTrafficLayer(this.map)
    }
  }

  reload() {
    document.getElementById('refreshed')!.style.display = 'none'
    document.getElementById('refreshing')!.style.display = 'inline'
    let currentEpoch = (new Date()).getTime()
    let isExpired = Math.abs(currentEpoch - this.lastUpdatedAt) > environment.refreshIntervalMillis * 2
    if (!isExpired) {
      return
    }
    this.load(this.rideId)
  }
}
